declare global {
  interface Window {
    __ENV?: Record<string, string>;
  }
}

type Environment = 'staging' | 'production' | 'development';

function getFromEnv(envKey: string): string | undefined {
  if (typeof window !== 'undefined' && window.__ENV) {
    return window.__ENV[envKey];
  }
  if (typeof process !== 'undefined' && process.env) {
    return process.env[envKey];
  }
  return undefined;
}

/**
 * Get environment variables from either window.__ENV or process.env.
 * If no key is provided, returns the current ENVIRONMENT.
 */
export function getEnv(): Environment;
export function getEnv<T extends string>(key: T): string | undefined;
export function getEnv(key: string = 'ENVIRONMENT'): string | undefined {
  if (key === 'ENVIRONMENT') {
    return getFromEnv(key) || 'development';
  }

  return getFromEnv(key);
}
